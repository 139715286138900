import React, { useEffect, useState } from 'react'
import {
    TextField,
    Grid,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    MenuItem,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { BtnSubmit, BtnReset, BtnBack } from '../../components/widgets/ButtonWidgets'
import { postApi } from '../../services/Api';
import Spinner from "../../components/shared/Spinner";
import { useNavigate, useParams } from 'react-router-dom';
import AlertBox from '../../components/shared/AlertBox';
import { YesNo } from '../../helpers/Data';


export default function NewMenu() {

    const { menuId } = useParams();
    const navigation = useNavigate();
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState();
    const [metas, setMetas] = useState([]);
    const [yesNo] = useState(YesNo);
    const { handleSubmit, control, formState: { errors }, reset } = useForm({
        defaultValues: {
            menuName: "",
            menuUrl: "",
            isActive: "",
            serialNo: "",
            metaId: ""
        }
    });

    useEffect(() => {
        getMetaList();
        if (menuId > 0) {
            setLoading(true);
            postApi("menu/menu-info", { menuId: menuId }).then(res => {
                setLoading(false);
                if (res.success) {
                    reset(res.menu);
                } else {
                    setMessage(res.message);
                }
            });
        }
    }, [menuId, reset]);

    function getMetaList() {
        postApi("meta/meta-list", {}).then(res => {
            if (res.success) {
                setMetas(res.metas);
            }
        });
    }

    function onSubmit(data) {

        setLoading(true);
        if (menuId > 0) {
            postApi("menu/update-menu", data).then(res => {
                setLoading(false);
                if (res.success) {
                    navigation("/menu/menu-list");
                } else {
                    setMessage(res.messgae);
                }
            });
        } else {
            postApi("menu/save-menu", data).then(res => {
                setLoading(false);
                if (res.success) {
                    navigation("/menu/menu-list");
                } else {
                    setMessage(res.messgae);
                }
            });
        }
    }


    function onReset() {

    }

    return (
        <div className="container">
            {loading && <Spinner />}
            {message != null && (
                <AlertBox severity="warning" message={message} />
            )}
            <form autoComplete="off" noValidate>
                <Card variant="outlined">
                    <CardHeader
                        title="Menu Form"
                        action={
                            <BtnBack url='/menu/menu-list' />
                        }
                    />
                    <Divider />
                    <CardContent sx={{ p: 2 }}>
                        <Grid container spacing={2.5}>
                            <Grid item xs={4}>
                                <Controller
                                    control={control}
                                    name="menuName"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Menu Name"
                                            error={!!errors.menuName}
                                        />
                                    )} />

                            </Grid>
                            <Grid item xs={4}>
                                <Controller
                                    control={control}
                                    name="menuUrl"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Menu Url"
                                            error={!!errors.menuUrl}
                                        />
                                    )} />
                            </Grid>
                            <Grid item xs={4}>
                                <Controller
                                    control={control}
                                    name="serialNo"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Serial No"
                                            error={!!errors.serialNo}
                                        />
                                    )} />

                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="isActive"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Is Active"
                                            error={!!errors.isPublish}
                                            select>
                                            {
                                                yesNo.map((res) => (
                                                    <MenuItem value={res.value} key={res.value}>{res.label}</MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <Controller
                                    control={control}
                                    name="metaId"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Meta"
                                            error={!!errors.metaId}
                                            select>
                                            {
                                                metas.map((res) => (
                                                    <MenuItem value={res.metaId} key={res.metaId}>{res.metaTitle}</MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    )}
                                />
                            </Grid>

                        </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions>
                        <BtnReset onReset={onReset} />
                        <BtnSubmit onClick={handleSubmit(onSubmit)} />
                    </CardActions>
                </Card>
            </form>
        </div>
    )
}
