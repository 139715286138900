import React, { useEffect, useState } from 'react'
import {
    TextField,
    Grid,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { BtnSubmit, BtnReset, BtnBack } from '../../components/widgets/ButtonWidgets'
import { postApi } from '../../services/Api';
import Spinner from "../../components/shared/Spinner";
import { useNavigate, useParams } from 'react-router-dom';
import AlertBox from '../../components/shared/AlertBox';


export default function NewMeta() {

    const { metaId } = useParams();
    const navigation = useNavigate();
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState();
    const { handleSubmit, control, formState: { errors }, reset } = useForm({
        defaultValues: {
            metaTitle: "",
            metaKeyword : "",
            metaDescription: "",
            metaImage: ""
        }
    });

    useEffect(() => {
        if (metaId > 0) {
            setLoading(true);
            postApi("meta/meta-info", { metaId: metaId }).then(res => {
                setLoading(false);
                if (res.success) {
                    reset(res.meta);
                } else {
                    setMessage(res.message);
                }
            });
        }
    }, [metaId, reset]);



    function onSubmit(data) {

        setLoading(true);
        if(metaId > 0){
            postApi("meta/update-meta", data).then(res => {
                setLoading(false);
                if (res.success) {
                    navigation("/meta/meta-list");
                } else {
                   setMessage(res.messgae);
                }
            });
        }else{
            postApi("meta/save-meta", data).then(res => {
                setLoading(false);
                if (res.success) {
                    navigation("/meta/meta-list");
                } else {
                   setMessage(res.messgae);
                }
            });
        }
    }


    function onReset() {

    }

    return (
        <div className="container">
            {loading && <Spinner />}
            {message != null && (
                <AlertBox severity="warning" message={message} />
            )}
            <form autoComplete="off" noValidate>
                <Card variant="outlined">
                    <CardHeader
                        title="Meta Form"
                        action={
                            <BtnBack url='/meta/meta-list' />
                        }
                    />
                    <Divider />
                    <CardContent sx={{ p: 2 }}>
                        <Grid container spacing={2.5}>
                            <Grid item xs={9}>
                                <Controller
                                    control={control}
                                    name="metaTitle"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Meta Title"
                                            error={!!errors.metaTitle}
                                        />
                                    )} />

                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="metaImage"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Meta Image"
                                            error={!!errors.metaImage}
                                        />
                                    )} />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="metaKeyword"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Meta Keyword"
                                            error={!!errors.metaKeyword}
                                        />
                                    )} />

                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="metaDescription"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Meta Description"
                                            error={!!errors.metaDescription}
                                        />
                                    )} />
                            </Grid>

                        </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions>
                        <BtnReset onReset={onReset} />
                        <BtnSubmit onClick={handleSubmit(onSubmit)} />
                    </CardActions>
                </Card>
            </form>
        </div>
    )
}
