import React, { useEffect, useState } from 'react'
import { DataGrid } from "@mui/x-data-grid";
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    Box
} from "@mui/material";
import {
    BtnSearch,
    BtnClear,
    BtnCreate,
    BtnExport
} from '../../components/widgets/ButtonWidgets';
import { postApi } from "../../services/Api";
import Spinner from '../../components/shared/Spinner';
import AlertBox from '../../components/shared/AlertBox';
import { IcnEdit } from '../../components/widgets/IconWidgets';
import { useNavigate } from 'react-router-dom';

export default function MetaList() {

    const navigate = useNavigate();
    const [metas, setMetas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("");

    useEffect(() => {
        getMetaList();
    }, []);

    function btnEdit(row) {
        navigate("/meta/new-meta/" + row.id);
    }

    const columns = [
        { field: "metaTitle", headerName: "Meta Title", flex: 1 },
        { field: "metaDescription", headerName: "Meta Description", flex: 1 },
        { field: "metaImage", headerName: "Meta Image", flex: 1 },
        {
            field: "action",
            headerName: "Action",
            renderCell: (row) => {
                return (
                    <IcnEdit onClick={() => btnEdit(row)} />
                );
            },
        },
    ];

    function getMetaList() {
        postApi("meta/meta-list", {}).then((res) => {
            setLoading(false);
            if (res.success) {
                setMetas(res.metas);
            } else {
                setMessage(res.message);
            }
        });
    }

    return (
        <div className="container">
            {
                message  && <AlertBox severity="warning" message={message} />
            }
            {loading && <Spinner />}
            <Card variant="outlined">
                <CardHeader
                    title="Meta List"
                    action={
                        <BtnCreate url='/meta/new-meta' />
                    }
                ></CardHeader>
                <Divider />
                <CardContent>
                    <Grid container spacing={2} sx={{ p: 1 }}>
                        <Grid item xs={3}>
                            <TextField
                                label="ss" size="small" />
                        </Grid>
                        <Grid item xs={3}>
                            <BtnSearch />
                            <BtnClear />
                        </Grid>
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="flex-end">
                                <BtnExport />
                            </Box>
                        </Grid>
                    </Grid>
                    <DataGrid
                        getRowId={(row) => row.metaId}
                        rows={metas}
                        columns={columns}
                        autoHeight={true}
                        headerHeight={35}
                        rowHeight={35}
                        rowsPerPageOptions={[20, 50, 100]}
                    />
                </CardContent>
            </Card>
        </div>
    );
}
