import { Backdrop, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export default function Spinner() {
  return (
    <Backdrop
      sx={{
        color: "#f2f2f2",
        zIndex: (theme) => theme.zIndex.modal + 1,
      }}
      open={true}
    >
      <Box>
        <CircularProgress
          thickness={5}
          color="inherit"
          size="5rem"
          value={80}
        />
        <Box sx={{ fontSize: 15, mt: 2, fontWeight: "bold" }}>
          Loading ...
        </Box>
      </Box>
    </Backdrop>
  );
}