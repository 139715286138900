import {
  KeyboardArrowDown,
  Home
} from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function TopMenu() {

  return (
    <div className="container">
      <ul className="top-menu">

        <li className="dropdown">
          <Link to="/">
            <Home
              fontSize="small"
              sx={{
                marginRight: 0.5,
                verticalAlign: "text-top",
              }}
            />
            Dashboard
          </Link>
        </li>

        <li className="dropdown">
          <Link>
            <Home
              fontSize="small"
              sx={{
                marginRight: 0.5,
                verticalAlign: "text-top",
              }}
            />
            Meta
            <KeyboardArrowDown
              fontSize="small"
              sx={{
                marginLeft: 0.5,
                verticalAlign: "sub",
              }}
            />
          </Link>
          <ul className="sub-menu">
            <li className="dropdown">
              <Link to="/meta/meta-list">
                <Home
                  fontSize="small"
                  sx={{
                    marginRight: 0.5,
                    verticalAlign: "text-top",
                  }}
                />
                Meta List
              </Link>
            </li>
          </ul>
        </li>

        <li className="dropdown">
          <Link>
            <Home
              fontSize="small"
              sx={{
                marginRight: 0.5,
                verticalAlign: "text-top",
              }}
            />
            Article
            <KeyboardArrowDown
              fontSize="small"
              sx={{
                marginLeft: 0.5,
                verticalAlign: "sub",
              }}
            />
          </Link>
          <ul className="sub-menu">
            <li className="dropdown">
              <Link to="article/article-list">
                <Home
                  fontSize="small"
                  sx={{
                    marginRight: 0.5,
                    verticalAlign: "text-top",
                  }}
                />
                Article List
              </Link>
            </li>
            <li className="dropdown">
              <Link to="topic/topic-list">
                <Home
                  fontSize="small"
                  sx={{
                    marginRight: 0.5,
                    verticalAlign: "text-top",
                  }}
                />
                Topic List
              </Link>
            </li>
          </ul>
        </li>

        <li className="dropdown">
          <Link>
            <Home
              fontSize="small"
              sx={{
                marginRight: 0.5,
                verticalAlign: "text-top",
              }}
            />
            Menu
            <KeyboardArrowDown
              fontSize="small"
              sx={{
                marginLeft: 0.5,
                verticalAlign: "sub",
              }}
            />
          </Link>
          <ul className="sub-menu">
            <li className="dropdown">
              <Link to="/menu/menu-list">
                <Home
                  fontSize="small"
                  sx={{
                    marginRight: 0.5,
                    verticalAlign: "text-top",
                  }}
                />
                Menu List
              </Link>
            </li>
            <li className="dropdown">
              <Link to="/category/category-list">
                <Home
                  fontSize="small"
                  sx={{
                    marginRight: 0.5,
                    verticalAlign: "text-top",
                  }}
                />
                Category List
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}