import React, { useEffect, useState } from 'react'
import {
    TextField,
    Grid,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    MenuItem,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { BtnSubmit, BtnReset, BtnBack } from '../../components/widgets/ButtonWidgets'
import { postApi } from '../../services/Api';
import Spinner from "../../components/shared/Spinner";
import { useNavigate, useParams } from 'react-router-dom';
import AlertBox from '../../components/shared/AlertBox';
import { YesNo } from '../../helpers/Data';


export default function NewCategory() {

    const { categoryId } = useParams();
    const navigation = useNavigate();
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState();
    const [metas, setMetas] = useState([]);
    const [menus, setMenus] = useState([]);
    const [yesNo] = useState(YesNo);
    const { handleSubmit, control, formState: { errors }, reset } = useForm({
        defaultValues: {
            categoryName: "",
            categoryUrl: "",
            isActive: "",
            serialNo: "",
            metaId: "",
            menuId: ""
        }
    });

    useEffect(() => {
        getMenuList();
    }, []);

    useEffect(() => {
        getMetaList();
        if (categoryId > 0) {
            setLoading(true);
            postApi("category/category-info", { categoryId: categoryId }).then(res => {
                setLoading(false);
                if (res.success) {
                    reset(res.category);
                } else {
                    setMessage(res.message);
                }
            });
        }
    }, [categoryId, reset]);

    function getMetaList() {
        postApi("meta/meta-list", {}).then(res => {
            if (res.success) {
                setMetas(res.metas);
            }
        });
    }

    function getMenuList() {
        postApi("menu/menu-list", {}).then(res => {
            if (res.success) {
                setMenus(res.menus);
            }
        });
    }

    function onSubmit(data) {

        setLoading(true);
        if (categoryId > 0) {
            postApi("category/update-category", data).then(res => {
                setLoading(false);
                if (res.success) {
                    navigation("/category/category-list");
                } else {
                    setMessage(res.messgae);
                }
            });
        } else {
            postApi("category/save-category", data).then(res => {
                setLoading(false);
                if (res.success) {
                    navigation("/category/category-list");
                } else {
                    setMessage(res.messgae);
                }
            });
        }
    }


    function onReset() {

    }

    return (
        <div className="container">
            {loading && <Spinner />}
            {message != null && (
                <AlertBox severity="warning" message={message} />
            )}
            <form autoComplete="off" noValidate>
                <Card variant="outlined">
                    <CardHeader
                        title="Category Form"
                        action={
                            <BtnBack url='/category/category-list' />
                        }
                    />
                    <Divider />
                    <CardContent sx={{ p: 2 }}>
                        <Grid container spacing={2.5}>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="categoryName"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Category Name"
                                            error={!!errors.categoryName}
                                        />
                                    )} />

                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="categoryUrl"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Category Url"
                                            error={!!errors.categoryUrl}
                                        />
                                    )} />
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="serialNo"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Serial No"
                                            error={!!errors.serialNo}
                                        />
                                    )} />

                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="menuId"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Menu"
                                            error={!!errors.menuId}
                                            select>
                                            {
                                                menus.map((res) => (
                                                    <MenuItem value={res.menuId} key={res.menuId}>{res.menuName}</MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="isActive"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Is Active"
                                            error={!!errors.isPublish}
                                            select>
                                            {
                                                yesNo.map((res) => (
                                                    <MenuItem value={res.value} key={res.value}>{res.label}</MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <Controller
                                    control={control}
                                    name="metaId"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Meta"
                                            error={!!errors.metaId}
                                            select>
                                            {
                                                metas.map((res) => (
                                                    <MenuItem value={res.metaId} key={res.metaId}>{res.metaTitle}</MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    )}
                                />
                            </Grid>

                        </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions>
                        <BtnReset onReset={onReset} />
                        <BtnSubmit onClick={handleSubmit(onSubmit)} />
                    </CardActions>
                </Card>
            </form>
        </div>
    )
}
