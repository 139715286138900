import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
  palette: {
    primary: {
      main: "#007447",
    },
    secondary: {
      main: "#17a2b8",
    },
  },
  components: {
    MuiInputLabel: {
      defaultProps: {
        sx: {
          color: "#777777",
        },
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        sx: {
          ml: 0,
          color: "#ed0537",
        },
      },
    },
    MuiDataGrid: {
      defaultProps: {
        sx: {
          m: 0,
          borderLeft: 0,
          borderRight: 0,
          borderBottom: 0,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          '& .MuiDataGrid-columnHeaders': {
            height: '30px',
            minHeight: '35px !important',
            overflow: 'hidden'
          },
        },
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        sx: {
          padding: "10px 10px 7px 10px",
          textTransform: "uppercase",
          fontWeight: "bold",
          color: "#444",
          fontSize: 16,
        },
      },
    },
    MuiDialogContent: {
      defaultProps: {
        sx: {
          p: 2.3,
        },
      },
    },
    MuiDialogActions: {
      defaultProps: {
        sx: {
          p: 1.4,
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: "h2",
          fontSize: '18px',
          fontWeight: "bold",
          color: "#606060",
        },
        sx: {
          padding: "10px 15px",
          textTransform: "uppercase",
          backgroundColor: "#fff",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          ":last-child": {
            paddingBottom: 0,
          },
        },
      },
      defaultProps: {
        sx: {
          p: 0,
        },
      },
    },
    MuiCardActions: {
      defaultProps: {
        sx: {
          justifyContent: "flex-end",
          padding: "10px 15px 10px 0"
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        startIcon: {
          marginRight: "4px",
          fontSize: "18px",
        },
        endIcon: {
          marginLeft: "3px",
          fontSize: "18px",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        sx: {
          width: "100%",
        },
      },

    },
    MuiAutocomplete: {
      defaultProps: {
        sx: {
          width: "100%",
        },
      },
    },
  },
});

export default Theme;
