import { IconButton } from "@mui/material";
import { Edit } from '@mui/icons-material';


function IcnEdit({ onClick }) {
    return (
        <IconButton
            variant="outlined"
            size="small"
            color='primary'
            sx={{
                height: 25,
                fontSize: 12,
            }}
            onClick={onClick}
        >
            <Edit />
        </IconButton>
    );
}

export { IcnEdit }