import "./assets/styles/theme.scss";
import "./assets/styles/main.scss";
import "./assets/styles/menu.scss";

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Error from "./components/theme/Error";
import Layout from "./components/theme/Layout";
import { ThemeProvider } from "@mui/material/styles";
import Theme from "./helpers/Theme";
import Login from "./modules/Shared/Login";
import AuthGuard from "./services/AuthGuard";
import Dashboard from "./routes/Dashboard";
import AppRoute from "./routes/AppRoute";


function App() {
    return (<ThemeProvider theme={Theme}>
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />} exact={true} />
                <Route
                    element={<AuthGuard>
                        <Layout />
                    </AuthGuard>}>
                    {Dashboard}
                    {AppRoute}
                    <Route path="*" element={<Error />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </ThemeProvider>);
}

export default App;
