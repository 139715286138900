import { Route } from "react-router-dom";
import Welcome from "../modules/Dashboard/Welcome";
import AuthGuard from "../services/AuthGuard";

const Dashboard = [
    <Route
        path="/"
        element={<AuthGuard>
            <Welcome />
        </AuthGuard>}
        exact={true}
        key="welcome"
    />];

export default Dashboard;