export const Gender = [
  { genderId: "M", genderName: "Male" },
  { genderId: "F", genderName: "Female" },
];

export const YesNo = [{
  value: true,
  label: "Yes"
}, {
  value: false,
  label: "No"
}];