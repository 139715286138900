import React, { useEffect, useState } from 'react'
import {
    TextField,
    Grid,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    MenuItem,
    Autocomplete
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { BtnSubmit, BtnReset, BtnBack } from '../../components/widgets/ButtonWidgets'
import { postApi } from '../../services/Api';
import { useNavigate, useParams } from 'react-router-dom';
import { YesNo } from '../../helpers/Data';
import AlertBox from '../../components/shared/AlertBox';
import Spinner from '../../components/shared/Spinner';
import {
    ClassicEditor,
    Font,
    Bold,
    FontFamily,
    Code,
    Essentials,
    Markdown,
    PageBreak,
    HtmlComment,
    List,
    MediaEmbed,
    Table,
    FullPage,
    Italic,
    Paragraph,
    Link,
    Image,
    AutoImage,
    ImageInsert,
    ImageCaption,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    LinkImage,
    Heading,
    Highlight,
    PasteFromOffice,
    SourceEditing,
    Alignment,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    FontColor,
    FontBackgroundColor,
    FontSize,
    Indent,
    TableProperties,
    TableCellProperties,
    TextPartLanguage,
    RemoveFormat,
    SpecialCharacters,
    SpecialCharactersEssentials,
    TodoList,
} from 'ckeditor5';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import 'ckeditor5/ckeditor5.css';

export default function NewArticle() {

    const { articleId } = useParams();
    const navigation = useNavigate();
    const [categories, setCategories] = useState([]);
    const [metas, setMetas] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [menus, setMenus] = useState([]);
    const [yesNo] = useState(YesNo);
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState();
    const [topics, setTopics] = useState([]);
    const { handleSubmit, control, formState: { errors }, reset } = useForm({
        defaultValues: {
            articleId: "",
            articleUrl: "",
            authorId: "",
            menuId: "",
            categoryId: "",
            isPublish: "",
            isFeature: "",
            metaId: "",
            articleImage: "",
            articleSummary: "",
            articleDescription: "",
            isVideo: "",
            videoUrl: "",
        }
    });

    useEffect(() => {
        getTopicList();
        getMetaList();
        getCategoryList();
        getAuthorList();
        getMenuList();
    }, []);

    useEffect(() => {
        if (articleId > 0) {
            setLoading(true);
            postApi("article/article-info", { articleId: articleId }).then(res => {
                setLoading(false);
                if (res.success) {
                    reset(res.article);
                } else {
                    setMessage(res.message)
                }
            });
        }
    }, [articleId, reset]);

    function handleMenu(event) {
        getCategoryList(event.target.value);
    }

    function getMetaList() {
        postApi("meta/meta-list", {}).then(res => {
            if (res.success) {
                setMetas(res.metas);
            }
        });
    }

    function getTopicList() {
        postApi("topic/topic-list", {}).then(res => {
            if (res.success) {
                setTopics(res.topics);
            }
        });
    }


    function getAuthorList() {
        postApi("author/author-list", {}).then(res => {
            if (res.success) {
                setAuthors(res.authors);
            }
        });
    }

    function getMenuList() {
        postApi("menu/menu-list", {}).then(res => {
            if (res.success) {
                setMenus(res.menus);
            }
        });
    }

    function getCategoryList(menuId) {
        postApi("category/category-list", { menuId: menuId }).then(res => {
            if (res.success) {
                setCategories(res.categories);
            }
        });
    }

    function onSubmit(data) {

        setLoading(true);

        if (articleId > 0) {
            postApi("article/update-article", data).then(res => {
                setLoading(false);
                if (res.success) {
                    navigation("/article/article-list");
                } else {
                    setMessage(res.message);
                }
            });
        } else {
            postApi("article/save-article", data).then(res => {
                if (res.success) {
                    setLoading(false);
                    navigation("/article/article-list");
                } else {
                    setMessage(res.message);
                }
            });
        }

    }

    function onReset() {

    }

    return (
        <div className="container">
            {
                message && <AlertBox severity="error" message={message} />
            }
            {
                loading && <Spinner />
            }
            <form autoComplete="off" noValidate>
                <Card variant="outlined">
                    <CardHeader
                        title="Article Form"
                        action={
                            <BtnBack url='/article/article-list' />
                        }
                    />
                    <Divider />
                    <CardContent sx={{ p: 2 }}>
                        <Grid container spacing={2.5}>
                            <Grid item xs={9}>
                                <Controller
                                    control={control}
                                    name="articleUrl"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Article Url"
                                            error={!!errors.articleUrl}
                                        />
                                    )} />
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="authorId"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Author"
                                            select
                                            error={!!errors.authorId}>
                                            {
                                                authors.map((res) => (
                                                    <MenuItem value={res.authorId} key={res.authorId}>{res.authorName}</MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="menuId"
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Menu"
                                            error={!!errors.menuId}
                                            onChange={handleMenu}
                                            select>
                                            {
                                                menus.map((res) => (
                                                    <MenuItem value={res.menuId} key={res.menuId}>{res.menuName}</MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="categoryId"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Category"
                                            error={!!errors.categoryId}
                                            select>
                                            {
                                                categories.map((res) => (
                                                    <MenuItem value={res.categoryId} key={res.categoryId}>{res.categoryName}</MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="isPublish"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Is Publish"
                                            error={!!errors.isPublish}
                                            select>
                                            {
                                                yesNo.map((res) => (
                                                    <MenuItem value={res.value} key={res.value}>{res.label}</MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="isFeature"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Is Feature"
                                            error={!!errors.isFeature}
                                            select>
                                            {
                                                yesNo.map((res) => (
                                                    <MenuItem value={res.value} key={res.value}>{res.label}</MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    control={control}
                                    name="isVideo"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Is Video"
                                            error={!!errors.isFeature}
                                            select>
                                            {
                                                yesNo.map((res) => (
                                                    <MenuItem value={res.value} key={res.value}>{res.label}</MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <Controller
                                    control={control}
                                    name="metaId"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Meta"
                                            error={!!errors.metaId}
                                            select>
                                            {
                                                metas.map((res) => (
                                                    <MenuItem value={res.metaId} key={res.metaId}>{res.metaTitle}</MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="videoUrl"
                                    rules={{ required: false }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Video Url"
                                            error={!!errors.videoUrl}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="articleSummary"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            rows={2}
                                            multiline={true}
                                            label="Article Summary"
                                            error={!!errors.articleSummary}
                                        />
                                    )} />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="articleDescription"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <CKEditor
                                            {...field}
                                            editor={ClassicEditor}
                                            config={{
                                                plugins: [Essentials,
                                                    Bold,
                                                    List,
                                                    PageBreak,
                                                    Markdown,
                                                    MediaEmbed,
                                                    Table,
                                                    HtmlComment,
                                                    Code,
                                                    FullPage,
                                                    Italic,
                                                    Paragraph,
                                                    Link,
                                                    FontFamily,
                                                    Alignment,
                                                    Image,
                                                    AutoImage,
                                                    ImageInsert,
                                                    ImageToolbar,
                                                    ImageCaption,
                                                    ImageStyle,
                                                    ImageResize,
                                                    LinkImage,
                                                    Heading,
                                                    Highlight,
                                                    Font,
                                                    PasteFromOffice,
                                                    Alignment,
                                                    Bold,
                                                    Italic,
                                                    Underline,
                                                    Strikethrough,
                                                    Subscript,
                                                    Superscript,
                                                    FontColor,
                                                    FontBackgroundColor,
                                                    FontFamily,
                                                    FontSize,
                                                    Indent,
                                                    Table,
                                                    TableProperties,
                                                    TableCellProperties,
                                                    TextPartLanguage,
                                                    RemoveFormat,
                                                    SpecialCharacters,
                                                    SpecialCharactersEssentials,
                                                    TodoList,
                                                    SourceEditing
                                                ],
                                                menuBar: {
                                                    isVisible: true
                                                },
                                                toolbar: {
                                                    items: [
                                                        'undo',
                                                        'redo',
                                                        'blockQuote',
                                                        'codeBlock',
                                                        'outdent',
                                                        'indent',
                                                        'htmlComment',
                                                        'fullPage',
                                                        'removeFormat',

                                                    ],
                                                }
                                            }}
                                            data={field.value || ''}
                                            onChange={(event, editor) => {
                                                field.onChange(editor.getData());
                                            }}
                                            error={!!errors.articleDescription}
                                        />
                                    )} />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions>
                        <BtnReset onReset={onReset} />
                        <BtnSubmit onClick={handleSubmit(onSubmit)} />
                    </CardActions>
                </Card>
            </form>
        </div >
    )
}
