import { Route } from "react-router-dom";
import AuthGuard from "../services/AuthGuard";
import ArticleList from "../modules/Article/ArticleList";
import NewArticle from "../modules/Article/NewArticle";
import MenuList from "../modules/Menu/MenuList";
import NewMenu from "../modules/Menu/NewMenu";
import CategoryList from "../modules/Menu/CategoryList";
import NewCategory from "../modules/Menu/NewCategory";
import MetaList from "../modules/Article/MetaList";
import NewMeta from "../modules/Article/NewMeta";
import TopicList from "../modules/Article/TopicList";
import NewTopic from "../modules/Article/NewTopic";


const AppRoute = [
    <Route
        path="/meta/meta-list"
        element={<AuthGuard>
            <MetaList />
        </AuthGuard>}
        exact={true}
        key="agList"
    />,
    <Route
        path="/meta/new-meta/:metaId?"
        element={<AuthGuard>
            <NewMeta />
        </AuthGuard>}
        exact={true}
        key="agList"
    />,
    <Route
        path="/article/article-list"
        element={<AuthGuard>
            <ArticleList />
        </AuthGuard>}
        exact={true}
        key="agList"
    />,
    <Route
        path="/article/new-article/:articleId"
        element={<AuthGuard>
            <NewArticle />
        </AuthGuard>}
        exact={true}
        key="newAg"
    />,
    <Route
        path="/article/new-article"
        element={<AuthGuard>
            <NewArticle />
        </AuthGuard>}
        exact={true}
        key="newAg"
    />,
    <Route
        path="/menu/menu-list"
        element={<AuthGuard>
            <MenuList />
        </AuthGuard>}
        exact={true}
        key="ldList"
    />,
    <Route
        path="/menu/new-menu/:menuId"
        element={<AuthGuard>
            <NewMenu />
        </AuthGuard>}
        exact={true}
        key="newLd"
    />,
    <Route
        path="/category/category-list"
        element={<AuthGuard>
            <CategoryList />
        </AuthGuard>}
        exact={true}
        key="ldList"
    />,
    <Route
        path="/category/new-category"
        element={<AuthGuard>
            <NewCategory />
        </AuthGuard>}
        exact={true}
        key="newLd"
    />,
    <Route
        path="/category/new-category/:categoryId"
        element={<AuthGuard>
            <NewCategory />
        </AuthGuard>}
        exact={true}
        key="newLd"
    />,
    <Route
        path="/topic/topic-list"
        element={<AuthGuard>
            <TopicList />
        </AuthGuard>}
        exact={true}
        key="tdList"
    />,
    <Route
        path="/topic/new-topic"
        element={<AuthGuard>
            <NewTopic />
        </AuthGuard>}
        exact={true}
        key="newLd"
    />,
    <Route
        path="/topic/new-topic/:topicId"
        element={<AuthGuard>
            <NewTopic />
        </AuthGuard>}
        exact={true}
        key="newLd"
    />,
];

export default AppRoute;