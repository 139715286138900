import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    Box
} from "@mui/material";
import {
    BtnSearch,
    BtnClear,
    BtnCreate,
    BtnExport
} from '../../components/widgets/ButtonWidgets';
import { postApi } from "../../services/Api";
import Spinner from '../../components/shared/Spinner';
import AlertBox from '../../components/shared/AlertBox';
import { useNavigate } from 'react-router-dom';
import { imageUrl } from '../../helpers/Constants';
import { IcnEdit } from '../../components/widgets/IconWidgets';

export default function ArticleList() {

    const navigate = useNavigate();
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("");

    useEffect(() => {
        fetchData();
    }, []);

    function fetchData() {
        postApi("article/article-list", {}).then((res) => {
            setLoading(false);
            if (res.success) {
                setArticles(res.articles);
            } else {
                setMessage(res.message);
            }
        });
    }


    function btnEdit(row){
        navigate("/article/new-article/" + row.articleId);
    }

    return (
        <div className="container">
            {message && (
                <AlertBox severity="warning" message={message} />
            )}
            {loading && <Spinner />}
            <Card variant="outlined">
                <CardHeader
                    title="Article List"
                    action={
                        <BtnCreate url='/article/new-article' />
                    }
                ></CardHeader>
                <Divider />
                <CardContent>
                    <Grid container spacing={2} sx={{ p: 1 }}>
                        <Grid item xs={3}>
                            <TextField
                                label="ss" size="small" />
                        </Grid>
                        <Grid item xs={3}>
                            <BtnSearch />
                            <BtnClear />
                        </Grid>
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="flex-end">
                                <BtnExport />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                articles.map((article) => (
                                    <Grid container>
                                        <Grid item xs={2}>
                                            <img src={imageUrl + '780X450/' + article.meta.metaImage} alt='' width='100%' />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <h3>{article.meta.metaTitle}</h3>
                                            <IcnEdit onClick={() => btnEdit(article)} />
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
}
